<template>
  <v-form v-model="formValid">
    <v-row>
      <v-col cols="12">
        <div class="custom-heading">Kontaktní údaje</div>
      </v-col>
      <v-col class="pt-0 pb-0" cols="6" md="6" sm="12">
        <v-text-field
          v-model.trim="form.contactPersonFirstName"
          :disabled="inputsDisabled"
          :rules="[validation.required, validation.maxLength(255)]"
          class="rounded-0"
          dense
          label="Jméno"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col class="pt-0 pb-0" cols="6" md="6" sm="12">
        <v-text-field
          v-model.trim="form.contactPersonLastName"
          :disabled="inputsDisabled"
          :rules="[validation.required, validation.maxLength(255)]"
          class="rounded-0"
          dense
          label="Příjmení"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col class="pt-0 pb-0" cols="8">
        <v-text-field
          v-model.trim="form.contactPersonStreet"
          :disabled="inputsDisabled"
          :rules="[validation.required, validation.maxLength(255)]"
          class="rounded-0"
          dense
          label="Ulice"
          outlined>
        </v-text-field>
      </v-col>
      <v-col class="pt-0 pb-0" cols="4">
        <v-text-field
          v-model.trim="form.contactPersonStreetNumber"
          :disabled="inputsDisabled"
          :rules="[validation.required, validation.maxLength(255)]"
          class="rounded-0"
          dense
          label="Č. p."
          outlined>
        </v-text-field>
      </v-col>
      <v-col class="pt-0 pb-0" md="6" sm="12">
        <v-text-field
          v-model.trim="form.contactPersonCity"
          :disabled="inputsDisabled"
          :rules="[validation.required, validation.maxLength(255)]"
          class="rounded-0"
          dense
          label="Město"
          outlined>
        </v-text-field>
      </v-col>
      <v-col class="pt-0 pb-0" md="6" sm="12">
        <v-text-field
          v-model.trim="form.contactPersonZip"
          :disabled="inputsDisabled"
          :rules="[validation.required, validation.maxLength(255)]"
          class="rounded-0"
          dense
          label="PSČ"
          outlined>
        </v-text-field>
      </v-col>
      <v-col class="pt-0 pb-0" md="6" sm="12">
        <v-text-field
          v-model="form.contactPersonEmail"
          :disabled="inputsDisabled"
          :rules="[validation.required, validation.email, validation.maxLength(255)]"
          class="rounded-0"
          dense
          label="Email"
          outlined
          type="email">
        </v-text-field>
      </v-col>
      <v-col class="pt-0 pb-0" md="6" sm="12">
        <v-text-field
          v-model.trim="form.contactPersonPhone"
          :disabled="inputsDisabled"
          :rules="[validation.required, validation.maxLength(255)]"
          class="rounded-0"
          dense
          label="Telefon"
          outlined>
        </v-text-field>
      </v-col>
      <v-col class="pt-0 pb-0" cols="12">
        <v-btn
          v-if="!inputsDisabled"
          :disabled="fetchingUserInfo"
          :loading="fetchingUserInfo"
          dense
          @click="fillFromProfile"
        >Vyplnit z profilu
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="custom-heading">Obecné info</div>
      </v-col>
      <v-col class="pt-0 pb-0" md="12" sm="12">
        <v-checkbox
          v-model="form.firm"
          :disabled="inputsDisabled"
          label="Závazná"
        />
      </v-col>
      <v-col class="pt-0 pb-0" md="6" sm="12">
        <v-text-field
          v-model.number="form.adults"
          :disabled="inputsDisabled"
          :rules="[validation.required]"
          class="rounded-0 pb-0 pt-0"
          dense
          min="0"
          label="Počet dopělých"
          outlined
          type="number"
        >
        </v-text-field>
      </v-col>
      <v-col class="pt-0 pb-0" md="6" sm="12">
        <v-text-field
          v-model.number="form.children"
          :disabled="inputsDisabled"
          :rules="[validation.required]"
          class="rounded-0"
          dense
          min="0"
          label="Počet dětí"
          outlined
          type="number">
        </v-text-field>
      </v-col>
      <v-col class="pt-0" cols="12">
        <vc-date-picker
          v-model="datePicker"
          :columns="2"
          :popover="{ visibility: inputsDisabled ? 'hidden' : 'focus' }"
          color="blue"
          is-range
          @input="getDemandPrice"
          locale="en-GB">
          <template v-slot="{ datePicker: inputValue, inputEvents }">
            <div class="d-flex justify-center items-center">
              <v-text-field
                v-on="inputEvents.start"
                :disabled="inputsDisabled"
                :value="getLocalizedDate(datePicker.start)"
                class="rounded-0 border-right-none"
                dense
                hide-details
                outlined
                placeholder="Od"
                prepend-inner-icon="mdi-calendar"
                readonly
              />
              <v-icon color=""
                      style="border-radius: 0px; border-top: 1px rgba(0, 0, 0, 0.38) solid; border-bottom: 1px rgba(0, 0, 0, 0.38) solid;padding: 7px 0 7px 0; background-color: white">
                mdi-arrow-right-thick
              </v-icon>
              <v-text-field
                v-on="inputEvents.end"
                :disabled="inputsDisabled"
                :value="getLocalizedDate(datePicker.end)"
                class="rounded-0 border-left-none"
                dense
                hide-details
                outlined
                placeholder="Do"
                readonly
              />
            </div>
          </template>
        </vc-date-picker>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="form.note"
          :disabled="inputsDisabled"
          class="rounded-0"
          dense
          label="Poznámka"
          outlined
          rows="4"></v-textarea>
      </v-col>
    </v-row>

    <v-row class="mt-4" no-gutters>
      <v-col class="d-flex justify-end mb-7" cols="12">
        <div class="custom-heading">
          Celkem:
          <v-progress-circular
            v-if="fetchingPrice"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <template v-else>
            {{ demandPrice }} Kč
          </template>
        </div>
      </v-col>
      <!-- Nezobrazuje se pro poptavky s kuponem -->
      <template
        v-if="(!this.demand.id || !this.demand.couponId) && this.user.id === demand.user.id"
      >
        <v-col class="justify-end d-flex" cols="8">
          <v-autocomplete
            v-model="form.coupon"
            :items="coupons"
            :readonly="inputsDisabled"
            class="rounded-0 "
            clearable
            dense
            item-text="code"
            label="Slevové kupóny"
            outlined
            return-object
            style="max-width: 250px"
          >
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="!!demand.id"
          cols="4">
          <v-btn
            :disabled="!form.coupon"
            class="rounded-0"
            color="primary"
            width="100%"
            @click="addCoupon"
          >
            Použít kupon
          </v-btn>
        </v-col>
      </template>
      <v-col
        v-else-if="demand.coupon"
        class="text-right"
        cols="12">
        Použitý kupon: {{ demand.coupon.code }}
      </v-col>
    </v-row>

    <v-row class="d-flex justify-end">
      <v-btn
        v-if="!inputsDisabled"
        :disabled="!formValid || runningRequest || fetchingPrice"
        class="rounded-0"
        color="primary"
        @click="save"
      >
        {{ demand.id ? 'Uložit' : 'Odeslat poptávku' }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    /**
     @typedef {{
        adults: Number | String,
        canceled: Boolean,
        children: Number | String,
        contactPersonCity: String,
        contactPersonEmail: String,
        contactPersonFirstName: String,
        contactPersonLastName: String,
        contactPersonPhone: String,
        contactPersonStreet: String,
        contactPersonStreetNumber: String,
        contactPersonZip: String,
        dateFrom: String,
        dateTo: String,
        firm: Boolean,
        id: Number | null,
        note: String,
        price: Number,
        property: Object,
        room: Object,
        user: Object,
        inputsDisabled: Boolean,
        couponId: Number | null
      }} Demand
     */
    demand: {
      type: Object,
      required: true
    },
    // inputy jsou disabled pro rezervace nebo zrusene poptavky
    inputsDisabled: {
      type: Boolean,
      default: false
    }
  },
  name: 'DemandForm',
  data () {
    return {
      runningRequest: false,
      fetchingUserInfo: false,
      fetchingPrice: false,
      formValid: true,
      datePicker: {
        start: null,
        end: null
      },
      form: {
        contactPersonEmail: null,
        contactPersonPhone: null,
        contactPersonFirstName: null,
        contactPersonLastName: null,
        contactPersonStreet: null,
        contactPersonStreetNumber: null,
        contactPersonCity: null,
        contactPersonZip: null,
        adults: null,
        children: null,
        dateTo: null,
        dateFrom: null,
        note: null,
        firm: null,
        coupon: null
      },
      priceWithoutCoupon: 0,
      profileInfo: null,
      coupons: []
    }
  },
  methods: {
    async fillFromProfile () {
      this.fetchingUserInfo = true
      if (!this.profileInfo) {
        await this.$http.get(`/users/${this.demand.user.id}`)
          .then(res => {
            this.profileInfo = res.data
          })
      }

      this.fetchingUserInfo = false
      this.form.contactPersonFirstName = this.profileInfo.firstName
      this.form.contactPersonLastName = this.profileInfo.lastName
      this.form.contactPersonEmail = this.profileInfo.email
      this.form.contactPersonPhone = this.profileInfo.phone
      this.form.contactPersonStreetNumber = this.profileInfo.streetNumber
      this.form.contactPersonStreet = this.profileInfo.street
      this.form.contactPersonZip = this.profileInfo.zip
      this.form.contactPersonCity = this.profileInfo.city
    },
    save () {
      this.runningRequest = true
      this.timeSlotError = false
      this.capacityError = false
      const requestBody = {
        ...this.form,
        dateTo: this.extractDate(this.datePicker.end),
        dateFrom: this.extractDate(this.datePicker.start),
        propertyId: this.demand.property.id,
        roomId: this.demand.room.id,
        couponId: this.form.coupon ? this.form.coupon.id : null
      }
      delete requestBody.coupon
      this.$http({
        method: this.demand.id ? 'PUT' : 'POST',
        url: this.demand.id ? `/demands/${this.demand.id}` : '/demands',
        data: requestBody
      })
        .then(res => {
          this.$emit('edit-success')
          if (this.isUser) {
            this.$router.push({ name: 'user.demands' })

            this.getDemandPrice()
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.setAlerts([{
              message: 'Objekt nenalezen',
              type: 'error'
            }])
          }
          // TODO: refactor
          if (err.response.data.message === 'TIMESLOT_NOT_AVAILABLE') {
            this.setAlerts([{
              message: 'Vybraný termín je již obsazený',
              type: 'error'
            }])
          }
          if (err.response.data.message === 'INVALID_CAPACITY') {
            this.setAlerts([{
              message: 'Překročena kapacita jednotky',
              type: 'error'
            }])
          }
          if (err.response.data.message === 'INVALID_START_DATE') {
            this.setAlerts([{
              message: 'Neplatný začátek rezervace',
              type: 'error'
            }])
          }
          if (err.response.data.message === 'PROPERTY_OWNER') {
            this.setAlerts([{
              message: 'Přihlášený uživatel je majitelem objektu',
              type: 'error'
            }])
          }
          if (err.response.data.message === 'INVALID_COUPON') {
            this.setAlerts([{
              message: 'Neplatný kupón',
              type: 'error'
            }])
          }
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    loadDemandPrice () {},
    getDemandPrice () {
      this.fetchingPrice = true
      this.$http.post('/demands/price', {
        dateFrom: this.extractDate(this.datePicker.start),
        dateTo: this.extractDate(this.datePicker.end),
        propertyId: this.demand.property.id,
        roomId: this.demand.room.id
      })
        .then(res => {
          this.priceWithoutCoupon = res.data.price
        })
        .finally(() => {
          this.fetchingPrice = false
        })
    },
    getCoupons () {
      const userId = this.demand.userId || this.user.id
      this.$http.get(`/users/${userId}/coupons`)
        .then(res => {
          this.coupons = res.data
        })
    },
    addCoupon () {
      /*
      Doresit ceny
      backend taky nebeha
      */
      this.$http.post(`/demands/${this.demand.id}/add-coupon`, {
        coupon_id: this.form.coupon.id
      })
        .then(res => {
          this.$emit('edit-success')
        })
        .catch(err => {
          if (err.response.data.message === 'ALREADY_HAS_COUPON') {
            this.setAlerts([{
              message: 'Na poptávku již byl použit kupón',
              type: 'error'
            }])
          }
          if (err.response.data.message === 'INVALID_COUPON') {
            this.setAlerts([{
              message: 'Neplatný kupón',
              type: 'error'
            }])
          }
        })
      // console.log(this.form.coupon)
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  computed: {
    demandPrice () {
      // zobrazena poptavka v historii, bez editovani
      if (this.demand.price && !this.priceWithoutCoupon) {
        return this.demand.price
      }

      let price = this.priceWithoutCoupon
      if (this.form.coupon) {
        if (this.form.coupon.type === 1) { // %
          price = Math.round(price * (100 - this.form.coupon.value) / 100)
        } else {
          price -= this.form.coupon.value
        }
      }

      return price
    },
    ...mapGetters({
      isUser: 'userAuth/isUser',
      property: 'detailViews/property',
      user: 'userAuth/user'
    })
  },
  created () {
    this.loadDemandPrice = this.debounce(this.getDemandPrice, 2000)
    this.form = {
      ...this.demand
    }

    delete this.form.createdAt
    delete this.form.price
    delete this.form.rating
    delete this.form.id
    delete this.form.property
    delete this.form.user
    delete this.form.canceled
    delete this.form.room

    this.datePicker.end = new Date(this.demand.dateTo)
    this.datePicker.start = new Date(this.demand.dateFrom)

    this.getCoupons()
    if (!this.demand.id) {
      this.getDemandPrice()
    }
  },
  watch: {
    demandPrice (val) {
      this.$emit('fetched-demand-price', val)
    },
    'datePicker.start': function () {
      this.loadDemandPrice()
    },
    'datePicker.end': function () {
      this.loadDemandPrice()
    } /* ,
    'form.coupon.id': function () {
      this.loadDemandPrice()
    } */
  }
}
</script>

<style scoped>
#form-padding {
}

>>> .border-left-none fieldset {
  border-left: none !important;
}

>>> .border-right-none fieldset {
  border-right: none !important;
}

.avail-subtitle {
  font-size: 22px;
  color: #888888
}
</style>
