<template>
  <v-col cols="12" xs="12" sm="12" md="12" lg="6">
    <v-row>
      <v-col cols="12">
        <v-card
          elevation="4"
          class="mx-auto"
        >
          <v-card-text>
            <v-alert v-if="notFoundError" type="error">Karta nenalezena</v-alert>
            <v-row no-gutters v-if="property">
              <v-col class="col-sm-4 d-flex justify-center align-content-center">
                <v-img
                  v-if="property.miniPhotoPath"
                  height="200px"
                  :src="backendPublicUrl + property.miniPhotoPath"
                ></v-img>
                <v-icon
                  v-else
                  size="25"
                >
                  mdi-camera-outline
                </v-icon>
              </v-col>
              <v-col class="col-sm-8">
                <v-card-title class="pt-1">
                  <span class="property-title mr-4">{{ property.name }}</span>
                  <span class="property-reviews d-flex">
                      <v-rating
                        readonly
                        color="orange"
                        half-increments
                        :value="property.rating"
                        length="5"
                        size="22"
                      >
                      </v-rating>
                      <span class="property-rating-value align-self-center">
                        ({{ property.rating }})
                      </span>
                    </span>
                </v-card-title>
                <v-card-text>{{ property.descriptionPreview }}</v-card-text>
                <v-row no-gutters class="align-center">
                  <v-col cols="9" class="text-left">
                    <v-card-subtitle>
                      <div>
                        <v-icon small style="vertical-align: text-top" color="green">mdi-map-marker</v-icon>{{ property.localityDisplayName }}
                      </div>
                    </v-card-subtitle>
                  </v-col>
                  <v-col cols="3" class="text-right">
                    <div class="object-price mr-3">
                      <span v-if="!!demand.price">
                        {{demand.price}}
                      </span>
                      <v-progress-circular
                        indeterminate
                        v-else
                        color="primary" />
                      Kč
                    </div>
                    <v-card-actions class="justify-end"><v-btn color="primary" data-redirect-button @click="redirectToDetail">Detail<v-icon>mdi-arrow-right</v-icon></v-btn></v-card-actions>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-skeleton-loader v-else type="card" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="property">
      <v-col cols="12">
        <div class="custom-heading-2">{{ property.room.name }}</div>
        <span class="avail-subtitle">Maximální počet hostů: {{ property.room.capacity }}</span>
      </v-col>
      <v-col cols="6">
        <div class="custom-heading-2">Check-in</div>
        <span class="avail-subtitle">{{ property.checkInFrom }}-{{ property.checkInTo }}</span>
      </v-col>
      <v-col cols="6">
        <div class="custom-heading-2">Check-out</div>
        <span class="avail-subtitle">{{ property.checkOutFrom }}-{{ property.checkOutTo }}</span>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DemandPropertyCardMiniature',
  props: {
    propertyId: {
      required: true,
      type: [Number, String]
    },
    roomId: {
      required: false,
      type: [Number, String]
    },
    demand: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      notFoundError: false,
      backendPublicUrl: process.env.VUE_APP_BACKEND_PUBLIC_URL
    }
  },
  created () {
    let url = `/properties/${this.propertyId}/mini-card`
    if (+this.roomId > 0) {
      url += `?room_id=${this.roomId}`
    }
    this.property = {
      room: {}
    }
    this.$http.get(url)
      .then(res => {
        this.property = res.data
      })
      .catch(err => {
        if (err.response.status === 404) {
          this.notFoundError = true
        }
      })
  },
  methods: {
    redirectToDetail () {
      this.$router.push({
        name: 'property-detail',
        params: { id: this.propertyId },
        query: {
          adults: this.demand.adults,
          children: this.demand.children,
          dateFrom: this.extractDate(new Date(this.demand.dateFrom)),
          dateTo: this.extractDate(new Date(this.demand.dateTo))
        }
      })
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  computed: {
    property: {
      get () {
        return this.$store.state.detailViews.property
      },
      set (val) {
        this.$store.state.detailViews.property = val
      }
    }
  }
}
</script>

<style scoped>

</style>
